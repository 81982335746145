import React from 'react';
import { FormInput } from '../../../../../../components/common/form_input';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { onlyNumeric, yesNoOptions } from '../../../helpers';
import { useFormContext } from 'react-hook-form';
import { ProfileCompletenessTag } from '../../components/profile_completeness_tag';
import { SectionHeading } from '../../components/section_heading';

const LongTermCareCostTab = () => {
    const { watch } = useFormContext();
    const inline = true;

    const isLongTermCareSelected = watch('LongTermCare') === 'Yes';
    const isRoomTypeSharedSelected = watch('RoomTypeShared') === 'Yes';
    const isRoomTypePrivateRoomSelected =
        watch('RoomTypePrivateRoom') === 'Yes';
    const isRoomTypeSemiPrivateSelected =
        watch('RoomTypeSemiPrivate') === 'Yes';

    const canUsePrivateRoomLongTerm =
        isLongTermCareSelected && isRoomTypePrivateRoomSelected;
    const canUseSemiPrivateRoomLongTerm =
        isLongTermCareSelected && isRoomTypeSemiPrivateSelected;
    const canUseSharedRoomLongTerm =
        isLongTermCareSelected && isRoomTypeSharedSelected;

    const allFieldsDisabled = !(
        canUsePrivateRoomLongTerm ||
        canUseSemiPrivateRoomLongTerm ||
        canUseSharedRoomLongTerm
    );

    return (
        <div className="mt-4">
            <SectionHeading
                title="Long Term Care Cost"
                subtitle="To edit the below inputs 'Long Term Care' and the relevant room type must be set to 'Yes'."
            />
            <div className="ps-2">
                <FormInput
                    allowedPattern={onlyNumeric}
                    disabled={!canUseSharedRoomLongTerm}
                    info={
                        <ProfileCompletenessTag
                            isVisible={canUseSharedRoomLongTerm}
                        />
                    }
                    inline={inline}
                    inlineLabelWidth="large"
                    label="Shared room"
                    maxWidth='270px'
                    name="LongTermSharedCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    disabled={!canUsePrivateRoomLongTerm}
                    info={
                        <ProfileCompletenessTag
                            isVisible={canUsePrivateRoomLongTerm}
                        />
                    }
                    inline={inline}
                    inlineLabelWidth="large"
                    label="Private room"
                    maxWidth='270px'
                    name="LongTermPrivateCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    disabled={!canUseSemiPrivateRoomLongTerm}
                    info={
                        <ProfileCompletenessTag
                            isVisible={canUseSemiPrivateRoomLongTerm}
                        />
                    }
                    inline={inline}
                    inlineLabelWidth="large"
                    label="Semi-private room"
                    maxWidth='270px'
                    name="LongTermSemiPrivateCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormRadioGroup
                    disabled={allFieldsDisabled}
                    groupLabel="Rates are all-inclusive?"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="LongTermRatesAllInclusive"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    disabled={allFieldsDisabled}
                    groupLabel="Rate packages/bundles available?"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="LongTermRatePackagesAvailable"
                    options={yesNoOptions}
                    showResetButton={true}
                />
            </div>
        </div>
    );
};

export { LongTermCareCostTab };
