import React from 'react';
import { FormInput } from '../../../../../../components/common/form_input';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { onlyNumeric, yesNoOptions } from '../../../helpers';
import { ProfileCompletenessTag } from '../../components/profile_completeness_tag';
import { useFormContext } from 'react-hook-form';
import { SectionHeading } from '../../components/section_heading';

const ShortTermCareCostTab = () => {
    const { watch } = useFormContext();
    const inline = true;

    const isShortTermCareSelected = watch('ShortTermCare') === 'Yes';
    const isRoomTypeSharedSelected = watch('RoomTypeShared') === 'Yes';
    const isRoomTypePrivateRoomSelected =
        watch('RoomTypePrivateRoom') === 'Yes';
    const isRoomTypeSemiPrivateSelected =
        watch('RoomTypeSemiPrivate') === 'Yes';

    const canUsePrivateRoomShortTerm =
        isShortTermCareSelected && isRoomTypePrivateRoomSelected;
    const canUseSemiPrivateRoomShortTerm =
        isShortTermCareSelected && isRoomTypeSemiPrivateSelected;
    const canUseSharedRoomShortTerm =
        isShortTermCareSelected && isRoomTypeSharedSelected;

    const allFieldsDisabled = !(
        canUsePrivateRoomShortTerm ||
        canUseSemiPrivateRoomShortTerm ||
        canUseSharedRoomShortTerm
    );

    return (
        <div className="mt-4">
            <SectionHeading
                title="Short Term Care Cost"
                subtitle="To edit the below inputs 'Short Term Care' and the relevant room type must be set to 'Yes'."
            />
            <div className="ps-2">
                <FormInput
                    allowedPattern={onlyNumeric}
                    disabled={!canUseSharedRoomShortTerm}
                    info={
                        <ProfileCompletenessTag
                            isVisible={canUseSharedRoomShortTerm}
                        />
                    }
                    inline={inline}
                    inlineLabelWidth="large"
                    label="Shared room"
                    maxWidth='270px'
                    name="ShortTermSharedCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    disabled={!canUsePrivateRoomShortTerm}
                    info={
                        <ProfileCompletenessTag
                            isVisible={canUsePrivateRoomShortTerm}
                        />
                    }
                    inline={inline}
                    inlineLabelWidth="large"
                    label="Private room"
                    maxWidth='270px'
                    name="ShortTermPrivateCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    disabled={!canUseSemiPrivateRoomShortTerm}
                    info={
                        <ProfileCompletenessTag
                            isVisible={canUseSemiPrivateRoomShortTerm}
                        />
                    }
                    inline={inline}
                    inlineLabelWidth="large"
                    label="Semi-private room"
                    maxWidth='270px'
                    name="ShortTermSemiPrivateCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormRadioGroup
                    disabled={allFieldsDisabled}
                    groupLabel="Rates are all-inclusive?"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="ShortTermRatesAllInclusive"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    disabled={allFieldsDisabled}
                    groupLabel="Rate packages/bundles available?"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="ShortTermRatePackagesAvailable"
                    options={yesNoOptions}
                    showResetButton={true}
                />
            </div>
        </div>
    );
};

export { ShortTermCareCostTab };
