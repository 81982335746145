import React, { useState, FC } from 'react';
import Select from 'react-select';
import Accordion from 'react-bootstrap/Accordion';
import { LoadingSpinner } from '../../../components/common/loading_spinner';
import { updateBulkPropertyContactDetails } from '../../../api/property';
import { Toast, showToast, ToastType } from '../../../utils/toast';
import { isObjectEmpty } from '../../../utils/common';
import { bulkUpdateContactDetailsObjectSchema } from './schema/contact_details_schema';
import {
    ContactDetailsHTMLType,
    ContactDetailsHeaders,
    IPropertyContactDetails
} from '../../../interfaces/property';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
    handleFetchData: any;
    contactData: IPropertyContactDetails[];
}

const bulkUpdateoptions = [
    { label: 'Ignore', value: 0 },
    { label: 'Update', value: 1 },
    { label: 'Update only if no existing data', value: 2 },
    { label: 'Delete', value: 3 }
];

const BulkPropertyContactDetailsUpdateForm: FC<IProps> = ({
    nhIDs,
    orgNHID,
    handleFetchData,
    contactData
}: IProps) => {
    const [busy, setBusy] = useState<boolean>(false);
    const [newBulkData, setNewBulkData] = useState<any>({});
    const [newBulkSChange, setNewBulkSChange] = useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = useState<any>({
        GeneralPhoneNumber: 0,
        GeneralEmail: 0,
        GeneralWebsite: 0,
        CarePhoneNumber: 0,
        JobPhoneNumber: 0,
        GeneralEmail2: 0,
        GeneralEmail3: 0,
        CareEmail: 0,
        CareEmail2: 0,
        CareEmail3: 0,
        TourEmail: 0,
        TourEmail2: 0,
        TourEmail3: 0,
        JobEmail: 0,
        JobEmail2: 0,
        JobEmail3: 0
    });
    const [bulkValidationError, SetBulkValidationError] = useState<any[]>([]);
    const [changedTick, setChangedTick] = useState<number>(0);

    const updateSelectedOptions = (selected: any, key: string) => {
        const newDataObject = selectedOptions;
        newDataObject[key] = selected.value;
        setSelectedOptions(newDataObject);
        if (selected.value > 0) {
            setNewBulkSChange(true);
        }
        if (
            (selected.value === 0 || selected.value === 3) &&
            bulkValidationError.length
        ) {
            const errorObjectArray = bulkValidationError.filter(function (e) {
                return e.Key !== key;
            });
            SetBulkValidationError(errorObjectArray);
        }
        setChangedTick(changedTick + 1);
    };

    const handleSetNewBulkData = (key: string, value: string) => {
        const newDataObject = newBulkData;
        newDataObject[key] = value;
        if (bulkValidationError.length) {
            const errorObjectArray = bulkValidationError.filter(function (e) {
                return e.Key !== key;
            });
            SetBulkValidationError(errorObjectArray);
        }
        setNewBulkData(newDataObject);
        setChangedTick(changedTick + 1);
    };

    const renderRowErrorMessage = (key: string) => {
        const errorObjectArray = bulkValidationError.filter(function (e) {
            return e.Key === key;
        });

        if (errorObjectArray.length) {
            return (
                <>
                    <small className="text-danger">
                        {`${errorObjectArray[0].Message} `}
                    </small>
                </>
            );
        }
    };

    const checkBulkObjectError = () => {
        if (bulkValidationError.length) {
            return true;
        }
        return false;
    };

    const checkBulkOptionUpdateSelected = () => {
        for (const i in selectedOptions) {
            if (selectedOptions[i] > 0) return true;
        }
        return false;
    };

    const checkIsDeletedOrIgnored = (key: string) => {
        if (selectedOptions[key] === 3 || selectedOptions[key] === 0) {
            return true;
        }
        return false;
    };

    const handleBulkUpdate = async () => {
        const payload = newBulkData;
        let updateValidationErrors: any[] = [];
        Object.entries(selectedOptions).forEach(([key, value]) => {
            if (parseInt(value as string) > 0 && !isNaN(value as number)) {
                payload[`${key}Op`] = selectedOptions[key];
                if (
                    (parseInt(value as string) === 1 ||
                        parseInt(value as string) === 2) &&
                    !payload[key]
                ) {
                    updateValidationErrors = updateValidationErrors.concat([
                        {
                            Key: key,
                            Message:
                                'Please type in a value for the Bulk Update'
                        }
                    ]);
                }
            } else {
                delete payload[key];
            }
        });

        try {
            await bulkUpdateContactDetailsObjectSchema.validate(payload, {
                abortEarly: false
            });
        } catch (err: any) {
            const errorCopy: any = err.inner;
            const errorPayload = errorCopy.map((e: any) => {
                const errorInnerCopy: any = {};
                errorInnerCopy.Key = e.path;
                errorInnerCopy.Message = e.message;
                return errorInnerCopy;
            });
            updateValidationErrors =
                updateValidationErrors.concat(errorPayload);
        }

        if (updateValidationErrors.length) {
            SetBulkValidationError(updateValidationErrors);
            setChangedTick(changedTick + 1);
            return;
        }

        payload.OrgNHID = orgNHID;
        payload.NHIDs = nhIDs ?? null;
        try {
            await bulkUpdateContactDetailsObjectSchema.validate(payload, {
                abortEarly: false
            });
        } catch (err: any) {
            const errorPayload: any = err.errors ?? [err.message];
            const newErrorArray = errorPayload;
            SetBulkValidationError(newErrorArray);
            setChangedTick(changedTick + 1);
            return;
        }
        try {
            setBusy(true);
            await updateBulkPropertyContactDetails(payload);
            const successString = `${contactData.length} Facility contact details saved successfully. Please allow up to 60 minutes for the changes to show on the website.`;
            showToast(successString, ToastType.Success);
            setSelectedOptions({
                GeneralPhoneNumber: 0,
                GeneralEmail: 0,
                GeneralWebsite: 0,
                CarePhoneNumber: 0,
                JobPhoneNumber: 0,
                GeneralEmail2: 0,
                GeneralEmail3: 0,
                CareEmail: 0,
                CareEmail2: 0,
                CareEmail3: 0,
                TourEmail: 0,
                TourEmail2: 0,
                TourEmail3: 0,
                JobEmail: 0,
                JobEmail2: 0,
                JobEmail3: 0
            });
            setNewBulkData({});
            setNewBulkSChange(false);
        } catch (e) {
            showToast(
                'An error occurred saving the updated contact details.',
                ToastType.Error
            );
            console.log(e);
        } finally {
            setBusy(false);
            handleFetchData();
        }
    };

    const renderLoading = () => <LoadingSpinner show={true} />;

    const renderBulkOptions = (
        htmlId: ContactDetailsHTMLType,
        key: string,
        label: string,
    ) => {
        let formClass;
        const labelId = `l-ct-${key}`;
        const selectId = `s-ct-${key}`;
        const fieldId = `f-ct-bulk-${key}`;

        const errorObjectArray = bulkValidationError.filter(function (e) {
            return e.Key === key;
        });
        let errorFormat = '';

        if (errorObjectArray.length) {
            errorFormat = 'border border-danger';
        }

        switch (htmlId) {
            case ContactDetailsHTMLType.Website:
                formClass = `form-control ${errorFormat}`;
                break;
            case ContactDetailsHTMLType.PhoneNumber:
                formClass = `form-control  mw-250px ${errorFormat}`;
                break;
            default:
                formClass = `form-control mw-400px ${errorFormat}`;
                break;
        }

        return (
            <div className="d-flex flex-column flex-sm-row align-items-md-center mb-4">
                <label
                    htmlFor={labelId}
                    className="col-form-label text-nowrap col-12 col-sm-2 mw-300px"
                >
                    {label}
                </label>
                <div className="column align-items-md-center w-25">
                    <Select
                        options={bulkUpdateoptions}
                        defaultValue={bulkUpdateoptions[0]}
                        id={selectId}
                        className=" mw-200px"
                        onChange={(selectedOption: any) =>
                            updateSelectedOptions(selectedOption, key)
                        }
                        value={
                            bulkUpdateoptions[selectedOptions[key]] ||
                            bulkUpdateoptions[0]
                        }
                    />
                </div>
                <div className="column w-100">
                    <input
                        type="text"
                        className={formClass}
                        id={fieldId}
                        value={(newBulkData as any)[key] || ''}
                        disabled={checkIsDeletedOrIgnored(key)}
                        onChange={e =>
                            handleSetNewBulkData(key, e.target.value)
                        }
                    />
                    {renderRowErrorMessage(key)}
                </div>
            </div>
        );
    };

    return (
        <>
            <Accordion className="mt-10 accordion-bulk-update">
                <Accordion.Item eventKey="0">
                    <Accordion.Header as="span">
                        <span className="m-0">
                            <h5 className="m-0">
                                Bulk Update all Facility Contact details
                            </h5>
                            <div className="mt-1 small">
                                Click to view options for bulk updating all
                                Facilities
                            </div>
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <span className="fw-bold header-title">Phone</span>
                        {renderBulkOptions(
                            ContactDetailsHTMLType.PhoneNumber,
                            'GeneralPhoneNumber',
                            ContactDetailsHeaders.GeneralPhoneNumber,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.PhoneNumber,
                            'CarePhoneNumber',
                            ContactDetailsHeaders.Care,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.PhoneNumber,
                            'JobPhoneNumber',
                            ContactDetailsHeaders.Job,
                        )}
                        <div>
                            <em>
                                The ‘Default Number’ will show if no separate
                                ‘Care’ or ‘Job’ numbers are entered
                            </em>
                        </div>
                        <br />
                        <span className="fw-bold header-title">Email</span>
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'GeneralEmail',
                            ContactDetailsHeaders.GeneralEmail1,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'GeneralEmail2',
                            ContactDetailsHeaders.GeneralEmail2,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'GeneralEmail3',
                            ContactDetailsHeaders.GeneralEmail3,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'CareEmail',
                            ContactDetailsHeaders.Care1,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'CareEmail2',
                            ContactDetailsHeaders.Care2,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'CareEmail3',
                            ContactDetailsHeaders.Care3,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'TourEmail',
                            ContactDetailsHeaders.Tour1,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'TourEmail2',
                            ContactDetailsHeaders.Tour2,
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'TourEmail3',
                            ContactDetailsHeaders.Tour3
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'JobEmail',
                            ContactDetailsHeaders.Job1
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'JobEmail2',
                            ContactDetailsHeaders.Job2
                        )}
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Email,
                            'JobEmail3',
                            ContactDetailsHeaders.Job3
                        )}
                        <div>
                            <em>
                                The ‘Default Email’ address(es) will be used if
                                no separate ‘Care', ‘Tour’ or ‘Job’ email
                                addresses are entered
                            </em>
                        </div>
                        <br />
                        <span className="fw-bold header-title ">Website</span>
                        {renderBulkOptions(
                            ContactDetailsHTMLType.Website,
                            'GeneralWebsite',
                            ContactDetailsHeaders.GeneralWebsite
                        )}
                        <div className="card-footer">
                            <button
                                type="button"
                                className="btn btn-primary text-nowrap"
                                disabled={
                                    (isObjectEmpty(newBulkData) &&
                                        !newBulkSChange &&
                                        !checkBulkOptionUpdateSelected()) ||
                                    checkBulkObjectError()
                                }
                                onClick={handleBulkUpdate}
                            >
                                Apply these Updates
                            </button>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {busy ? renderLoading() : null}
            <Toast></Toast>
        </>
    );
};

export { BulkPropertyContactDetailsUpdateForm };
