import React from 'react';
import { FormInput } from '../../../../../../components/common/form_input';
import { FormRadioGroup } from '../../../../../../components/common/form_radio_group';
import { onlyNumeric, yesNoOptions } from '../../../helpers';
import { useFormContext } from 'react-hook-form';
import { ProfileCompletenessTag } from '../../components/profile_completeness_tag';
import { SectionHeading } from '../../components/section_heading';

const RespiteCareCostTab = () => {
    const { watch } = useFormContext();

    const inline = true;

    const isRespiteCareSelected = watch('RespiteCare') === 'Yes';
    const isRoomTypeSharedSelected = watch('RoomTypeShared') === 'Yes';
    const isRoomTypePrivateRoomSelected =
        watch('RoomTypePrivateRoom') === 'Yes';
    const isRoomTypeSemiPrivateSelected =
        watch('RoomTypeSemiPrivate') === 'Yes';
    const isRespiteMinStayRequired =
        watch('RespiteMinStayRequirement') === 'Yes';

    const canUsePrivateRoomRespite =
        isRespiteCareSelected && isRoomTypePrivateRoomSelected;
    const canUseSemiPrivateRoomRespite =
        isRespiteCareSelected && isRoomTypeSemiPrivateSelected;
    const canUseSharedRoomRespite =
        isRespiteCareSelected && isRoomTypeSharedSelected;

    const allFieldsDisabled = !(
        canUsePrivateRoomRespite ||
        canUseSemiPrivateRoomRespite ||
        canUseSharedRoomRespite
    );

    return (
        <div className="mt-4">
            <SectionHeading title="Respite Care Cost" subtitle="To edit the below inputs 'Respite Care' and the relevant room type must be set to 'Yes'." />
            <div className="ps-2">
                <FormInput
                    allowedPattern={onlyNumeric}
                    disabled={!canUseSharedRoomRespite}
                    info={
                        <ProfileCompletenessTag
                            isVisible={canUseSharedRoomRespite}
                        />
                    }
                    inline={inline}
                    inlineLabelWidth="large"
                    label="Shared room"
                    maxWidth='270px'
                    name="RespiteSharedCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    disabled={!canUsePrivateRoomRespite}
                    info={
                        <ProfileCompletenessTag
                            isVisible={canUsePrivateRoomRespite}
                        />
                    }
                    inline={inline}
                    inlineLabelWidth="large"
                    label="Private room"
                    maxWidth='270px'
                    name="RespitePrivateCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormInput
                    allowedPattern={onlyNumeric}
                    disabled={!canUseSemiPrivateRoomRespite}
                    info={
                        <ProfileCompletenessTag
                            isVisible={canUseSemiPrivateRoomRespite}
                        />
                    }
                    inline={inline}
                    inlineLabelWidth="large"
                    label="Semi-private room"
                    maxWidth='270px'
                    name="RespiteSemiPrivateCostPerDay"
                    prefix="$"
                    suffix="starting price per day"
                    type="number"
                />
                <FormRadioGroup
                    disabled={!isRespiteCareSelected}
                    groupLabel="Minimum stay required?"
                    followUpInput={
                        <FormInput
                            allowedPattern={onlyNumeric}
                            disabled={
                                !isRespiteMinStayRequired ||
                                !isRespiteCareSelected
                            }
                            maxWidth='150px'
                            name="RespiteMinStay"
                            suffix="days"
                            type="number"
                        />
                    }
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="RespiteMinStayRequirement"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    disabled={allFieldsDisabled}
                    groupLabel="Rates are all-inclusive?"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="RespiteRatesAllInclusive"
                    options={yesNoOptions}
                    showResetButton={true}
                />
                <FormRadioGroup
                    disabled={allFieldsDisabled}
                    groupLabel="Rate packages/bundles available?"
                    info={<ProfileCompletenessTag isVisible={false} />}
                    inline={inline}
                    inlineLabelWidth="large"
                    name="RespiteRatePackagesAvailable"
                    options={yesNoOptions}
                    showResetButton={true}
                />
            </div>
        </div>
    );
};

export { RespiteCareCostTab };
