import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import { LoadingSpinner } from '../../../components/common/loading_spinner';
import { 
    IPropertyCoreDetailBasic,
    IPropertyCoreDetailInsuranceType,
    IPropertyCoreDetailPayload,
    IPropertyInsuranceTypesPayloadItem,
    IPropertyCoreDetailNHID
} from '../../../interfaces/property';
import { isBoolean } from '../../../utils/common';
import * as nhAPI from '../../../api/property';
import { updateProfileCompleteness } from '../../../utils/profile_completeness';
import { Toast, ToastType, showToast } from '../../../utils/toast';
import coreDetailsFormSchema from './property_core_details_form.validation';
import { numericOnly } from './helpers';

type DurationUnit =  'years' | 'days' | 'months';

interface IFormInputData { 
    Label: string;
    Key: keyof IPropertyCoreDetailPayload;
    IncludedInProfileCompleteness?: boolean;
    DurationUnit?: DurationUnit;
    MaxLength?: number;
    MinIntegerValue?: number;
    MaxIntegerValue?: number;
}

interface ICoreDetailFormProps {
    nhID: number
}

const PropertyCoreDetailsForm = ({ nhID }: ICoreDetailFormProps) => {
    const [ isLoading, setIsLoading ] = useState<boolean>();
    const [ isEdit, setIsEdit] = useState<boolean>(false);
    const [ insuranceTypes, setInsuranceTypes ] = useState<IPropertyCoreDetailInsuranceType[]>([]);

  
    const defaultCoreDetails: any = {
        LongTermCare: null,
        ShortTermCare: null,
        RespiteCare: null,
        OtherFacilityTypes: null,
        AffiliatedHospitals: null,
        AvgShortTermResidentsPc: null,
        AvgShortStayDuration: null,
        AvgLongTermResidentsPc: null,
        AvgLongTermStayDuration: null,
        MinAgeRequirement: null,
        MinAge: null,
        AdmitPediatricResidents: null,
        PediatricAvgShortTermResidentsPc: null,
        PediatricAvgShortStayDuration: null,
        PediatricAvgLongTermResidentsPc: null,
        PediatricAvgLongTermStayDuration: null,
        PediatricMaxAgeRequirement: null,
        PediatricMaxAge: null,
        RoomTypeOther: null,
        RoomTypeSemiPrivate: null,
        RoomTypeShared: null,
        RoomTypePrivateSuite: null,
        RoomTypePrivateRoom: null,
        LongTermSharedCostPerDay: null,
        LongTermPrivateCostPerDay: null,
        LongTermSemiPrivateCostPerDay: null,
        LongTermRatesAllInclusive: null,
        LongTermRatePackagesAvailable: null,
        ShortTermSharedCostPerDay: null,
        ShortTermPrivateCostPerDay: null,
        ShortTermSemiPrivateCostPerDay: null,
        ShortTermRatesAllInclusive: null,
        ShortTermRatePackagesAvailable: null,
        RespiteSharedCostPerDay: null,
        RespitePrivateCostPerDay: null,
        RespiteSemiPrivateCostPerDay: null,
        RespiteMinStayRequirement: null,
        RespiteMinStay: null,
        RespiteRatesAllInclusive: null,
        RespiteRatePackagesAvailable: null,
        AcceptMedicareAdvantage: null,
        AcceptMedicaidPending: null,
        BedsAllDualLicenced: null,
        BedsMedicareCount: null,
        BedsMedicaidCount: null,
        PrivateInsurancePc: null,
        ManagedCareContractsInNetwork: null,
        FacilityHasMemoryCareUnit: null,
        FacilityHasBehaviouralHealthUnit: null,
    };

    

    const onSubmit = async (e: any) => {
        const { ...coreDetails } = e;

        const insuranceTypes: IPropertyInsuranceTypesPayloadItem[] = [];
        Object.keys(coreDetails).forEach((key: string) => {
            if (key.startsWith('InsuranceType')) {
                const propertyInsuranceTypeID = Number(key.split('_')[1]);
                const isActive = coreDetails[key];

                if (isActive) {
                    insuranceTypes.push({
                        PropertyInsuranceTypeID: propertyInsuranceTypeID,
                        IsActive: isActive === '1' ? true : false
                    });                    
                }

                delete coreDetails[key];
            }
        });

        delete coreDetails['LastUpdated'];

        const payload: IPropertyCoreDetailPayload = {
            ...coreDetails,
            InsuranceTypes: insuranceTypes.length ? insuranceTypes : null
        };

        setIsLoading(true);

        try {
            await (nhAPI as any)[`${isEdit ? 'update' : 'create'}PropertyCoreDetailData`](nhID, payload);
            showToast(`Care Offered / Cost / Funding details successfully ${isEdit ? 'updated' : 'created'}. Please allow up to 60 minutes for the changes to show on the website.`, ToastType.Success);

            initForm();
                
            window.scrollTo(0, 0);
        } catch(err) {
            showToast('An error occurred saving Core Details.', ToastType.Error);
        } finally {
            if (!isEdit) {
                setIsEdit(true);
            }
            
            setIsLoading(false);
            updateProfileCompleteness();
        }
    };

    const getFormDefaults = async (): Promise<any> => {
        if (!isLoading) {
           setIsLoading(true); 
        }
        
        let formDefaults = {};

        try {

            const rows = await nhAPI.getPropertyCoreDetails();

            if (rows?.length) {

                const data = rows.find((item: IPropertyCoreDetailNHID) => item.NHID === nhID);

                if (data) {

                    const { InsuranceTypes, CoreDetails } = data;
                    
                    formDefaults = { 
                        ...setCoreDetailsDefaults(CoreDetails),
                        ...setInsuranceTypeDefaults(InsuranceTypes)
                    };

                    if (InsuranceTypes) {
                        setInsuranceTypes(InsuranceTypes); 
                    }
                    
                    if (CoreDetails?.NHID) {
                        setIsEdit(true);
                    }

                    return formDefaults;
                } else {
                    throw new Error(`Core details not found for nhid ${nhID}`);
                }
            }
        } catch(err) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `Error occurred getting Core Details: ${(err as any).message}`
            });  
        } finally {
            setIsLoading(false);

        }
    };

    const { 
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        getValues,
        setValue,
        trigger,
        reset
    } = useForm({
        resolver: yupResolver(coreDetailsFormSchema), 
        defaultValues:  async () => await getFormDefaults(),
    });
    
    const initForm = async () => {
        setIsEdit(false);
        const defaults = await getFormDefaults();
        reset({...defaults});
    };

    useEffect(() => {
       initForm();
    }, [ nhID ]);

    const watchMinAgeRequirement  = watch('MinAgeRequirement');
    const watchPediatricMaxAgeRequirement = watch('PediatricMaxAgeRequirement');
    const watchRespiteMinStayRequirement = watch('RespiteMinStayRequirement');

    const watchLongTermCare = watch('LongTermCare');
    const watchShortTermCare = watch('ShortTermCare');
    const watchRespiteCare = watch('RespiteCare');

    const watchLongTermCareSelected = watchLongTermCare === '1';
    const watchShortTermCareSelected = watchShortTermCare === '1';
    const watchRespiteCareSelected = watchRespiteCare === '1';

    const watchRoomTypeShared = watch('RoomTypeShared');
    const watchRoomTypePrivateRoom = watch('RoomTypePrivateRoom');
    const watchRoomTypeSemiPrivate = watch('RoomTypeSemiPrivate');

    const watchRoomTypeSharedSelected = watchRoomTypeShared === '1';
    const watchRoomTypePrivateRoomSelected = watchRoomTypePrivateRoom === '1';
    const watchRoomTypeSemiPrivateSelected = watchRoomTypeSemiPrivate === '1';

    const watchAdmitPediatricResidents = watch('AdmitPediatricResidents');
    const watchAdmitPediatricResidentsSelected = watchAdmitPediatricResidents === '1';

    useEffect(() => {
        if (watchMinAgeRequirement !== '1') {
            setValue('MinAge', null);
            trigger('MinAge');
        }

        if (watchPediatricMaxAgeRequirement !== '1') {
            setValue('PediatricMaxAge', null);
            trigger('PediatricMaxAge');
        }

        if (watchRespiteMinStayRequirement !== '1') {
            setValue('RespiteMinStay', null);
            trigger('RespiteMinStay');
        }
    }, [watchMinAgeRequirement, watchPediatricMaxAgeRequirement, watchRespiteMinStayRequirement]);

    const getRadioValue = (value: boolean) => value === true ? '1' : '0';

    const setCoreDetailsDefaults = (coreDetails: IPropertyCoreDetailBasic) => {
        let formDefaults: any = {};

        if (coreDetails) {
            for (const [key, value] of Object.entries(coreDetails)) {
                formDefaults[key] = isBoolean(value) ? getRadioValue(value) : value;
            }
        } else {
            formDefaults = { ...defaultCoreDetails };
        }

        return formDefaults;
    };

    const setInsuranceTypeDefaults = (insuranceTypes: IPropertyCoreDetailInsuranceType[]) => {
        if (!insuranceTypes) {
            return null;
        }

        const formDefaults: any = {};

        insuranceTypes.forEach(({ PropertyInsuranceTypeID, IsActive }: IPropertyCoreDetailInsuranceType) =>
            formDefaults[`InsuranceType_${PropertyInsuranceTypeID}`] = isBoolean(IsActive) ? getRadioValue(IsActive) : IsActive
        );
        
        return formDefaults;
    };

    const setTermData = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        source: string,
        target: string
    ) => {
        e.preventDefault();

        const sourceKeys = [
            'SharedCostPerDay',
            'PrivateCostPerDay',
            'SemiPrivateCostPerDay',
            'RatesAllInclusive',
            'RatePackagesAvailable'
        ];

        const sourceData = getValues(sourceKeys.map((key: string) => `${source}${key}`));

        sourceKeys.forEach((key: string, index: number) => setValue(`${target}${key}`, sourceData[index], {
            shouldDirty: true
        }));
    };

    const handleRadioReset = (key: string, e: any) => {
        e.preventDefault();
        setValue(key, null, { shouldDirty: true });    
    };

    const renderRadios = (key: string, readOnly: boolean) =>
        <>
            <div className="me-4">
                <div className='form-check'>
                    <label className="form-check-label text-nowrap">
                        <input 
                            className="form-check-input"
                            {...register(key as any)}
                            id={key}
                            name={key}
                            type="radio"
                            key={key}
                            value={1}
                            disabled={readOnly}
                        />
                        Yes
                        <span></span>
                    </label>
                </div>
            </div>
            <div className="me-4">
                <div className="form-check">
                    <label className="form-check-label text-nowrap">
                        <input 
                            className="form-check-input"
                            {...register(key as any)}
                            id={key}
                            name={key}
                            type="radio"
                            key={key}
                            value={0}
                            disabled={readOnly}
                        />
                        No
                        <span></span>
                    </label>                          
                </div>
            </div>
        </>;

    const renderRadioGroup = (data: IFormInputData[], readOnly: boolean) => 
        data.map(({ Label, Key, IncludedInProfileCompleteness }: IFormInputData, index: number) => 
            <div className="row mb-4" key={`key-${index}`}>
                <div className="col-sm-12 col-md-3 d-flex align-items-center">
                    { Label }
                </div>
                <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
                    <div className='d-flex align-items-center'>
                        { renderRadios(Key, readOnly) }
                        <div>
                            <button
                                title='reset'
                                onClick={(e) => handleRadioReset(Key, e)}
                                className="btn btn-icon btn-light btn-hover-secondary btn-sm mx-1"
                            >
                                <i className="text-primary fa fa-history" />
                            </button>
                        </div>
                        { 
                            IncludedInProfileCompleteness
                                ? <div><span className="badge bg-success signpost ms-4">Included in Profile Completeness</span></div> 
                                : null
                        }
                    </div>
                </div>
            </div>
        );

    const renderCostPerDay = (data: IFormInputData[], readOnly: boolean) => 

        data.map(({ Label, Key, IncludedInProfileCompleteness }: IFormInputData, index: number) => 
            <div className="row mb-4" key={`key-${index}`}>
                <div className="col-sm-12 col-md-3 d-flex align-items-center">
                    { Label }
                </div>
                <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
                    <div className='d-flex align-items-center'>
                        <div className="input-group">
                            <span className="input-group-text">$</span>
                            <input
                                {...register(Key as any)} 
                                type="number" 
                                className="form-control" 
                                maxLength={4}
                                size={1}
                                disabled={readOnly}
                            />
                            <span className="input-group-text">starting price per day</span>
                        </div> 
                        { 
                            IncludedInProfileCompleteness
                                ? <div><span className="badge bg-success signpost ms-4">Included in Profile Completeness</span></div> 
                                : null
                        }
                    </div>
                </div>
                {errors[Key] ? <p className="text-danger">{ (errors as any)[Key].message }</p> : null}     
            </div>
        );

    const renderPercent = (data: IFormInputData[], readOnly: boolean) => 
        data.map(({ Label, Key, IncludedInProfileCompleteness }: IFormInputData, index: number) => 
            <div className="row mb-4" key={`key-${index}`}>
                <div className="col-sm-12 col-md-3 d-flex align-items-center">
                    { Label }
                </div>
                <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
                    <div className='d-flex align-items-center'>
                        <div className="input-group">
                            <input 
                                {...register(Key as any)} 
                                onKeyDown={numericOnly}
                                type="number" 
                                className="form-control" 
                                maxLength={3} 
                                max={100}
                                min="0"
                                size={1}
                                disabled={readOnly}
                            />
                            <span className="input-group-text">%</span>
                        </div>
                        { 
                            IncludedInProfileCompleteness
                                ? <div><span className="badge bg-success signpost ms-4">Included in Profile Completeness</span></div> 
                                : null
                        }
                    </div>
                </div>
                {errors[Key] ? <p className="text-danger">{ (errors as any)[Key].message }</p> : null }         
            </div>
        );

        const renderInteger = (data: IFormInputData[], readOnly: boolean) => 
            data.map(({ Label, Key, MinIntegerValue, MaxIntegerValue, IncludedInProfileCompleteness }: IFormInputData, index: number) => 
                <div className="row mb-4" key={`key-${index}`}>
                    <div className="col-sm-12 col-md-3 d-flex align-items-center">
                        { Label }
                    </div>
                    <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
                        <div className='d-flex align-items-center'>
                            <div className="input-group">
                                <input 
                                    {...register(Key as any, {
                                        validate: { minValidFn: (value: string) => parseInt(value, 10) >= MinIntegerValue,
                                            maxValidFn: (value: string) => parseInt(value) <= MaxIntegerValue },
                                    })} 
                                    type="number" 
                                    className="form-control" 
                                    maxLength={3} 
                                    size={1}
                                    disabled={readOnly}
            
                                />
                            </div>
                            { 
                                IncludedInProfileCompleteness
                                    ? <div><span className="badge bg-success signpost ms-4">Included in Profile Completeness</span></div> 
                                    : null
                            }
                        </div>
                    </div>
                    {errors[Key] ? <p className="text-danger">{ (errors as any)[Key].message }</p> : null }         
                </div>
            );
    
    const renderDurationInput = (key: string, unit: DurationUnit, readOnly: boolean) =>
        <div className="input-group">
            <input 
                {...register(key as any)} 
                type="number" 
                className="form-control w-75px" 
                maxLength={4} 
                size={1}
                disabled={readOnly}
            />
            <span className="input-group-text">{ unit }</span>
        </div>;

    const renderDuration = ({ Label, Key, DurationUnit }: IFormInputData, readOnly: boolean) =>
        <div className="row mb-4">
            <div className="col-sm-12 col-md-3 d-flex align-items-center">
                { Label }
            </div>
            <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
                <div className='d-flex align-items-center'>
                    { renderDurationInput(Key, DurationUnit, readOnly) }
                </div>
            </div>
            {errors[Key] ? <p className="text-danger">{ (errors as any)[Key].message }</p> : null}   
        </div>;

    const renderText = (data: IFormInputData[], doubleWidth = false) => 
        data.map(({ Label, Key }: IFormInputData) => 
            <div className="row mb-4" key={`${Label}-${Key}`}>
                <div className="col-sm-12 col-md-3 d-flex align-items-center">
                    { Label }
                </div>
                <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
                    <div className={`align-items-center ${doubleWidth ? 'w-50' : ''}`}>
                            <input 
                                {...register(Key as any)} 
                                type="text" 
                                className="form-control"
                            />
                    </div>
                </div>
                {errors[Key] ? <p className="text-danger">{ (errors as any)[Key].message }</p> : null}   
            </div>
        );

    const renderOptionalDuration = ({ Label, Key, DurationUnit, IncludedInProfileCompleteness }: IFormInputData, readOnly: boolean) => {
        const requirementKey = `${Key}Requirement`;

        return (
            <div className="row mb-4">
                <div className="col-sm-12 col-md-3 d-flex align-items-center">
                    { Label }
                </div>
                <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
                    <div className='d-flex align-items-center'>
                        { renderRadios(requirementKey, readOnly) }
                        <div>
                            <button
                                title='reset'
                                onClick={(e) => handleRadioReset(requirementKey, e)}
                                className="btn btn-icon btn-light btn-hover-secondary btn-sm mx-1"
                            >
                                <i className="text-primary fa fa-history" />
                            </button>
                        </div>
                        { renderDurationInput(Key, DurationUnit, readOnly || watch(requirementKey) !== '1') }
                    </div>
                    
                    { 
                            IncludedInProfileCompleteness
                                ? <div><span className="badge bg-success signpost ms-4">Included in Profile Completeness</span></div> 
                                : null
                        }
                </div>
                {errors[Key] ? <p className="text-danger">{ (errors as any)[Key].message }</p> : null}   
            </div>           
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            
            {isLoading ? <LoadingSpinner /> : null}

            <Toast></Toast>

            <div>

                <div className="widget">
                    <div className="widget-body">
                        <div className="card mb-9 mb-md-0" data-card="Form" data-columns="1">
                            <div className="card-header">
                                <h4>Care Offered</h4>
                            </div>
                            <div className="card-body my-4">
                                {
                                    renderRadioGroup([
                                        { Label: 'Long term care', Key: 'LongTermCare', IncludedInProfileCompleteness: true },
                                        { Label: 'Short term care', Key: 'ShortTermCare', IncludedInProfileCompleteness: true },
                                        { Label: 'Respite care', Key: 'RespiteCare', IncludedInProfileCompleteness: true }
                                    ], false)
                                }

                                { renderOptionalDuration({ Label: 'Is there a minimum age requirement?', Key: 'MinAge', DurationUnit: 'years', IncludedInProfileCompleteness: true }, false) }

                                {
                                renderRadioGroup([
                                        { Label: 'Facility Has Memory Care Unit', Key: 'FacilityHasMemoryCareUnit', IncludedInProfileCompleteness: true },
                                        { Label: 'Facility Has Behavioural Health Unit', Key: 'FacilityHasBehaviouralHealthUnit', IncludedInProfileCompleteness: true }
                                    ], false)
                                }

                                { renderText([{ Label: 'Any other speciality/niche services?', Key: 'OtherFacilityTypes' }], true)}
                                { renderText([ { Label: 'Which hospitals is the facility affiliated with?', Key: 'AffiliatedHospitals' } ], true) }
                                { renderPercent([{ Label: 'Average % of short-term residents per month', Key: 'AvgShortTermResidentsPc', IncludedInProfileCompleteness: true }], false) }
                                { renderDuration({ Label: 'Average short-term stay duration', Key: 'AvgShortStayDuration', DurationUnit: 'days' }, false) }
                                { renderPercent([{ Label: 'Average % of long-term residents per month', Key: 'AvgLongTermResidentsPc', IncludedInProfileCompleteness: true }], false) }
                                { renderDuration({ Label: 'Average long-term stay duration', Key: 'AvgLongTermStayDuration', DurationUnit: 'months' }, false) }
                            </div>
                        </div>
                        <div className="card"> 
                            <div className="card-header">
                                <h4>Pediatric Residents</h4>
                            </div>
                            <div className="card-body my-4">
                                { renderRadioGroup([ { Label: 'Do you admit pediatric residents?', Key: 'AdmitPediatricResidents', IncludedInProfileCompleteness: true } ], false) }

                                { 
                                    renderPercent([{ 
                                        Label: 'Average % of short-term pediatric residents per month', 
                                        Key: 'PediatricAvgShortTermResidentsPc',
                                        IncludedInProfileCompleteness: watchAdmitPediatricResidentsSelected 
                                    }], !watchAdmitPediatricResidentsSelected) }

                                { renderDuration({ Label: 'Average short-term pediatric stay duration', Key: 'PediatricAvgShortStayDuration', DurationUnit: 'days' }, !watchAdmitPediatricResidentsSelected) }

                                { 
                                    renderPercent([{ 
                                        Label: 'Average % of long-term pediatric residents per month', 
                                        Key: 'PediatricAvgLongTermResidentsPc', 
                                        IncludedInProfileCompleteness: watchAdmitPediatricResidentsSelected 
                                    }], !watchAdmitPediatricResidentsSelected) }

                                { renderDuration({ Label: 'Average long-term pediatric stay duration', Key: 'PediatricAvgLongTermStayDuration', DurationUnit: 'months' }, !watchAdmitPediatricResidentsSelected) }
                                { renderOptionalDuration({ Label: 'Is there a maximum age requirement for pediatric residents?', Key: 'PediatricMaxAge', DurationUnit: 'years' }, false) }

                            </div>
                        </div>        

                        <div className="card"> 
                            <div className="card-header">
                                <h4>Room Type</h4>
                            </div>
                            <div className="card-body my-4">
                                <div className="row mb-4">
                                    <div className="">
                                    {                  
                                        renderRadioGroup([
                                            { Label: 'Shared', Key: 'RoomTypeShared', IncludedInProfileCompleteness: true },
                                            { Label: 'Private Room', Key: 'RoomTypePrivateRoom', IncludedInProfileCompleteness: true },
                                            { Label: 'Semi Private', Key: 'RoomTypeSemiPrivate', IncludedInProfileCompleteness: true },
                                            { Label: 'Private Suite', Key: 'RoomTypePrivateSuite', IncludedInProfileCompleteness: true },
                                        ], false)
                                    }
                                    </div>
                                </div>
                                { renderText([{ Label: 'Any other room types?', Key: 'RoomTypeOther' } ]) }
                            </div>
                        </div>        
                        
                        <div className="card"> 
                            <div className="card-header">
                                <span>
                                    <h4>Long Term Care Cost</h4>
                                    <small>To edit the below inputs &lsquo;Long Term Care&rsquo; and the relevant room type must be set to &lsquo;Yes&rsquo; above.</small>
                                </span>
                            </div>
                            <div className="card-body my-4">
                                { 
                                    renderCostPerDay([{ 
                                        Label: 'Shared room', 
                                        Key: 'LongTermSharedCostPerDay', 
                                        IncludedInProfileCompleteness: watchLongTermCareSelected && watchRoomTypeSharedSelected
                                    }], !watchLongTermCareSelected || !watchRoomTypeSharedSelected)}
                                { 
                                    renderCostPerDay([{ 
                                        Label: 'Private room', 
                                        Key: 'LongTermPrivateCostPerDay', 
                                        IncludedInProfileCompleteness: watchLongTermCareSelected && watchRoomTypePrivateRoomSelected
                                    }], !watchLongTermCareSelected || !watchRoomTypePrivateRoomSelected)}
                                { 
                                    renderCostPerDay([{ 
                                        Label: 'Semi-private room', 
                                        Key: 'LongTermSemiPrivateCostPerDay', 
                                        IncludedInProfileCompleteness: watchLongTermCareSelected && watchRoomTypeSemiPrivateSelected
                                    }], !watchLongTermCareSelected  || !watchRoomTypeSemiPrivateSelected)} 
                                {
                                    renderRadioGroup([
                                        { Label: 'Rates are all-inclusive?', Key: 'LongTermRatesAllInclusive' },
                                        { Label: 'Rate packages/bundles available?', Key: 'LongTermRatePackagesAvailable' }
                                    ], !(watchLongTermCareSelected && (watchRoomTypeSharedSelected || watchRoomTypePrivateRoomSelected || watchRoomTypeSemiPrivateSelected)))
                                } 
                            </div>
                        </div>

                        <div className="card"> 
                            <div className="card-header">
                                <span>
                                    <h4>Short Term Care Cost</h4>
                                    <small className="me-2">To edit the below inputs &lsquo;Short Term Care&rsquo; and the relevant room type must be set to &lsquo;Yes&rsquo; above.</small>
                                    <a 
                                        href="#" 
                                        className="small" 
                                        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => setTermData(e, 'LongTerm', 'ShortTerm')}
                                    >
                                        Use Long Term Care Prices
                                    </a>
                                </span>
                            </div>
                            <div className="card-body my-4">

                                { 
                                    renderCostPerDay([{ 
                                        Label: 'Shared room', 
                                        Key: 'ShortTermSharedCostPerDay', 
                                        IncludedInProfileCompleteness: watchShortTermCareSelected && watchRoomTypeSharedSelected
                                    }], !watchShortTermCareSelected || !watchRoomTypeSharedSelected)}
                                { 
                                    renderCostPerDay([{ 
                                        Label: 'Private room', 
                                        Key: 'ShortTermPrivateCostPerDay', 
                                        IncludedInProfileCompleteness: watchShortTermCareSelected && watchRoomTypePrivateRoomSelected
                                    }], !watchShortTermCareSelected||  !watchRoomTypePrivateRoomSelected)}
                                { 
                                    renderCostPerDay([{ 
                                        Label: 'Semi-private room', 
                                        Key: 'ShortTermSemiPrivateCostPerDay', 
                                        IncludedInProfileCompleteness: watchShortTermCareSelected && watchRoomTypeSemiPrivateSelected
                                    }], !watchShortTermCareSelected || !watchRoomTypeSemiPrivateSelected)} 
                                {
                                    renderRadioGroup([
                                        { Label: 'Rates are all-inclusive?', Key: 'ShortTermRatesAllInclusive' },
                                        { Label: 'Rate packages/bundles available?', Key: 'ShortTermRatePackagesAvailable' }
                                    ], !(watchShortTermCareSelected && (watchRoomTypeSharedSelected || watchRoomTypePrivateRoomSelected || watchRoomTypeSemiPrivateSelected)))
                                } 
                            </div>
                        </div>

                        <div className="card"> 
                            <div className="card-header">
                                <span>
                                    <h4>Respite Care Cost</h4>
                                    <small className="me-2">To edit the below inputs &lsquo;Respite Care&rsquo; and the relevant room type must be set to &lsquo;Yes&rsquo; above.</small>
                                    <a 
                                        href="#" 
                                        className="small" 
                                        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => setTermData(e, 'LongTerm', 'Respite')}
                                    >
                                        Use Long Term Care Prices
                                    </a>
                                    <small>&nbsp;or&nbsp;</small>
                                    <a 
                                        href="#" 
                                        className="small" 
                                        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => setTermData(e, 'ShortTerm', 'Respite')}
                                    >
                                        Use Short Term Care Prices
                                    </a>
                                </span>
                            </div>
                            <div className="card-body my-4">
                                { 
                                    renderCostPerDay([{ 
                                        Label: 'Shared room', 
                                        Key: 'RespiteSharedCostPerDay', 
                                        IncludedInProfileCompleteness: watchRespiteCareSelected && watchRoomTypeSharedSelected
                                    }], !watchRespiteCareSelected || !watchRoomTypeSharedSelected)}
                                { 
                                    renderCostPerDay([{ 
                                        Label: 'Private room', 
                                        Key: 'RespitePrivateCostPerDay', 
                                        IncludedInProfileCompleteness: watchRespiteCareSelected && watchRoomTypePrivateRoomSelected
                                    }], !watchRespiteCareSelected || !watchRoomTypePrivateRoomSelected)}
                                { 
                                    renderCostPerDay([{ 
                                        Label: 'Semi-private room', 
                                        Key: 'RespiteSemiPrivateCostPerDay', 
                                        IncludedInProfileCompleteness: watchRespiteCareSelected && watchRoomTypeSemiPrivateSelected
                                    }], !watchRespiteCareSelected || !watchRoomTypeSemiPrivateSelected)} 

                                { 
                                    renderOptionalDuration({ 
                                        Label: 'Minimum stay required?', 
                                        Key: 'RespiteMinStay', 
                                        DurationUnit: 'days' 
                                    },!(watchRespiteCareSelected && (watchRoomTypeSharedSelected || watchRoomTypePrivateRoomSelected || watchRoomTypeSemiPrivateSelected)))
                                }
                                {
                                    renderRadioGroup([
                                        { Label: 'Rates are all-inclusive?', Key: 'RespiteRatesAllInclusive' },
                                        { Label: 'Rate packages/bundles available?', Key: 'RespiteRatePackagesAvailable' }
                                    ], !(watchRespiteCareSelected && (watchRoomTypeSharedSelected || watchRoomTypePrivateRoomSelected || watchRoomTypeSemiPrivateSelected)))
                                } 
                            </div>
                        </div>
                        
                        <div className="card"> 
                            <div className="card-header">
                                <h4>Bed Allocation</h4>
                            </div>
                            <div className="card-body my-4">
                                { renderRadioGroup([{ Label: 'All beds dual-licenced for either Medicare or Medicaid?', Key: 'BedsAllDualLicenced', IncludedInProfileCompleteness: true }], false) } 
                                {
                                    renderInteger([
                                        { Label: 'Medicaid bed count', Key: 'BedsMedicaidCount', MinIntegerValue: 0, MaxIntegerValue: 999, IncludedInProfileCompleteness: true },
                                        { Label: 'Medicare bed count', Key: 'BedsMedicareCount', MinIntegerValue: 0, MaxIntegerValue: 999, IncludedInProfileCompleteness: true  }
                                    ], false)
                                }
                            </div>
                        </div>

                        <div className="card"> 
                            <div className="card-header">
                                <h4>Payment types</h4>
                            </div>
                            <div className="card-body my-4">
                                { renderPercent([{ Label: '% of residents/patients who use private insurance to pay for care', Key: 'PrivateInsurancePc' }], false) }
                                {
                                    renderRadioGroup([
                                        { Label: 'Managed care contracts accepted as in-network?', Key: 'ManagedCareContractsInNetwork', IncludedInProfileCompleteness: true },
                                        { Label: 'Do you accept Medicare Advantage plans?', Key: 'AcceptMedicareAdvantage', IncludedInProfileCompleteness: true },
                                        { Label: 'Do you accept Medicaid pending patients?', Key: 'AcceptMedicaidPending', IncludedInProfileCompleteness: true }
                                    ], false)
                                } 
                            </div>
                        </div>

                        <div className="card"> 
                            <div className="card-header">
                                <h4>Insurance Providers Accepted</h4>
                            </div>
                            <div className="card-body my-4 card-form">
                                {
                                    (insuranceTypes || []).map(({ PropertyInsuranceTypeID, Name }: IPropertyCoreDetailInsuranceType) =>
                                        <div className="row mb-4" key={`insurance-type-${PropertyInsuranceTypeID}`}>
                                            <div className="col-sm-12 col-md-3 d-flex align-items-center">
                                                { Name }
                                            </div>
                                            <div className="col-md-12 col-lg-8 col-xl-6 d-flex align-items-center">
                                                <div className='d-flex align-items-center'>
                                                    { renderRadios(`InsuranceType_${PropertyInsuranceTypeID}`, false) }
                                                </div>
                                                <div>
                                                    <button
                                                        title='reset'
                                                        onClick={(e) => handleRadioReset(`InsuranceType_${PropertyInsuranceTypeID}`, e)}
                                                        className="btn btn-icon btn-light btn-hover-secondary btn-sm mx-1"
                                                    >
                                                        <i className="text-primary fa fa-history" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div> 
                </div> 
            </div> 
            <div className="d-flex justify-content-center sticky-bottom my-4 pb-4">
                <button 
                    type="submit" 
                    className="btn btn-primary shadow"
                    disabled={!isDirty || isLoading}
                >
                    Update
                </button>
            </div>
        </form>
    );
};

export default PropertyCoreDetailsForm;
