import React from 'react';
import cx from 'classnames';

interface ProfileCompletenessTagProps {
    isVisible: boolean;
}

const ProfileCompletenessTag: React.FC<ProfileCompletenessTagProps> = ({ isVisible }) => {
    const bannerClassName = cx('badge badge-secondary signpost fw-normal', {
        'invisible': !isVisible
    });
    return (
        <div>
            <span className={bannerClassName}>
                Included in Profile
                <br /> Completeness
            </span>
        </div>
    );
};

export { ProfileCompletenessTag };