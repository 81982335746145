import React, { useEffect, useState } from 'react';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import { useLocation } from 'react-router-dom';
import { ISessionState, SessionType } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyTourAvailability = ({ nhIDs, orgNHID }: IProps) => {
    const location = useLocation();
    const state = location?.state as any;
    const defaultNHID = state?.NHID || null;
    const [selNHID, setSelNHID] = useState<number>(defaultNHID);
   
    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });
    
    const { Session }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const { SessionType: sessionType } = Session;

    useEffect(() => {
        const singleNHID = selNHID || (nhIDs?.length === 1 ? nhIDs[0] : null);
        if (singleNHID) {
            setSelNHID(singleNHID);
        }
    }, [nhIDs, orgNHID]);

    useEffect(() => {
       
    }, [selNHID]);

     return (
        <> 
            {/*<PageCommunitySelectWrapper
                label={'Tour Availability'}
                handleSelect={handleSelect}
                selNHID={selNHID}
            />*/}
            <img className="border mx-auto d-block" src="/images/screenshot-tour.png" />
        </>
    );
};

export default PropertyTourAvailability;