import { CareOfferedTab } from './sections/care_offered_tab';
import { PediatricResidentsTab } from './sections/pediatric_residents_tab';
import { RoomTypeTab } from './sections/room_type_tab';
import { LongTermCareCostTab } from './sections/long_term_care_cost_tab';
import { ShortTermCareCostTab } from './sections/short_term_care_cost_tab';
import { RespiteCareCostTab } from './sections/respite_care_cost_tab';
import { BedAllocationTab } from './sections/bed_allocation_tab';
import { PaymentTypesTab } from './sections/payment_types_tab';
import { InsuranceProvidersAcceptedTab } from './sections/insurace_providers_accepted_tab';
import { ITab } from '../../../../hooks/use_create_tabs';

const tabSections: ITab[] = [
    {
        tabHeader: 'Care Offered',
        tabKey: 'care-offered',
        tabContent: CareOfferedTab
    },
    {
        tabHeader: 'Pediatric Residents',
        tabKey: 'pediatric-residents',
        tabContent: PediatricResidentsTab
    },
    {
        tabHeader: 'Room Type',
        tabKey: 'room-type',
        tabContent: RoomTypeTab
    },
    {
        tabHeader: 'Long\u00A0Term Care\u00A0Cost',
        tabKey: 'long-term-care-cost',
        tabContent: LongTermCareCostTab,
    },
    {
        tabHeader: 'Short\u00A0Term Care\u00A0Cost',
        tabKey: 'short-term-care-cost',
        tabContent: ShortTermCareCostTab,
    },
    {
        tabHeader: 'Respite Care\u00A0Cost',
        tabKey: 'respite-care-cost',
        tabContent: RespiteCareCostTab,
    },
    {
        tabHeader: 'Bed Allocation',
        tabKey: 'bed-allocation',
        tabContent: BedAllocationTab
    },
    {
        tabHeader: 'Payment Types',
        tabKey: 'payment-types',
        tabContent: PaymentTypesTab
    },
    {
        tabHeader: 'Insurance Providers\u00A0Accepted',
        tabKey: 'insurance-providers-accepted',
        tabContent: InsuranceProvidersAcceptedTab
    }
];

export { tabSections };
