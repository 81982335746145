import * as yup from 'yup';

const yesNoOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
];

const yupNullableString = yup.string()
.typeError('Invalid text')
.max(500, 'Content must be 500 chars or less')
.transform((_, val) => (val?.trim() !== '' ? val: null))
.notRequired();

const yupNullableStringToNumber = yup.number()
.transform((_, val) => (val === null || val === '' ? null : Number(val)));

const yupNullableStringToBool = yup.bool()
.transform((_, val) => (val === null ? null : ['Yes', '1'].includes(val) ? true : false))
.notRequired();

const yupNullableStringToNumberPc = yupNullableStringToNumber
.max(100, '% values must be 100 or less')
.notRequired();

const yupNullableStringToNumberCost = yupNullableStringToNumber
.max(999, 'Values must be $999 or less')
.notRequired();

const yupNullableStringToNumberLT1000 = yupNullableStringToNumber
.min(1, 'Values must be between 1 and 999')
.max(999, 'Values must be between 1 and 999')
.notRequired();

const yupNullableZeroableStringToNumberLT1000 = yupNullableStringToNumber
.min(0, 'Values must be between 0 and 999')
.max(999, 'Values must be between 0 and 999')
.notRequired();

const yupNullableStringToNumberLT100 = yupNullableStringToNumber
.min(1, 'Values must be between 1 and 99')
.max(99, 'Values must be between 1 and 99')
.notRequired();

const yupStringToNumberLT100 = yupNullableStringToNumber
.min(1, 'Values must be between 1 and 99')
.max(99, 'Values must be between 1 and 99');

const yupStringToNumberLT1000 = yupNullableStringToNumber
.min(1, 'Values must be between 1 and 999')
.max(999, 'Values must be between 1 and 999');


const numericOnly = (e: any) => {
    
    const valExceedsNumericLimits = () => {
        const pcValue = parseInt(e.target.value, 10);
        const keyValue = parseInt(e.key, 10);

        if (isNaN(pcValue) || isNaN(keyValue)) {
            return false;
        }

        const newVal = parseInt(`${pcValue}${keyValue}`, 10);
        return (newVal < 0 || newVal > 100);
    };

    const hasLeadingZeroes = () => e.target.value.length && 
        e.target.value.charAt(0) ==='0';

    if (
      (!/[0-9]/.test(e.key) || valExceedsNumericLimits() || hasLeadingZeroes()) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'Enter'
    ) {
      e.preventDefault();
    }
};

const onlyNumeric = '^(0|[1-9][0-9]*?)$';
const onlyUpto100 = '^(0|[1-9][0-9]?|100)$';

const validationHelpers = {
    yupNullableString,
    yupNullableStringToNumber,
    yupNullableStringToNumberPc,
    yupNullableStringToNumberCost,
    yupNullableStringToNumberLT1000,
    yupNullableZeroableStringToNumberLT1000,
    yupNullableStringToNumberLT100,
    yupStringToNumberLT100,
    yupStringToNumberLT1000,
    yupNullableStringToBool
};

export { yesNoOptions, validationHelpers, numericOnly, onlyNumeric, onlyUpto100 };