import React from 'react';
import { Modal } from '../../../../components/common/modal';
import { Button } from '../../../../components/common/button';
import { Form } from '../../../../components/common/form';
import coreDetailsFormSchema from './../property_core_details_form.validation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as nhAPI from '../../../../api/property';
import { useState } from 'react';
import { mapValuesToFormFields } from './helpers';
import { useCreateTabs } from '../../../../hooks/use_create_tabs';
import { tabSections } from '../tabs/tab_sections';
import { longFormatDate } from '../../../../utils/date';

interface IProps {
    row: any;
    hasCoreDetails: boolean;
}

const CareOfferedModal = ({ row, hasCoreDetails }: IProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const { TabHeaders, TabContent } = useCreateTabs(tabSections, {
        isScrollTab: true
    });

    const apiCall = hasCoreDetails
        ? nhAPI.updatePropertyCoreDetailData
        : nhAPI.createPropertyCoreDetailData;

    const queryClient = useQueryClient();
    const { mutate, isPending } = useMutation({
        mutationFn: async (payload: any) => apiCall(row.NHID, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['property', 'core', 'details']
            });
            setIsOpen(false);
        }
    });

    const defaultValues = mapValuesToFormFields(row);

    const handleSubmit = async (data: any) => {
        await mutate(data);
    };

    const formId = String(row.NHID);

    const footerActions = (
        <Button form={formId} type="submit" isLoading={isPending}>
            Update
        </Button>
    );

    const lastUpdatedText = `Last Updated: ${row.LastUpdated ? longFormatDate(row.LastUpdated): 'No updates made' }`;

    const title = (
        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between">
            <div className='d-inline-flex flex flex-row flex-wrap gap-2 w-100'>
                <h4 className="m-0 d-inline me-2">Care Offered / Cost / Funding</h4>
                <span className='fs-6 font-weight-normal d-inline-block d-flex align-items-end'>{lastUpdatedText}</span>
            </div>
            <div className="fs-5 text-secondary fw-normal w-100 d-flex justify-content-md-end">{row.Name}</div>
        </div>
    );


    return (
        <>  
            <Button onClick={() => setIsOpen(true)} size="auto">
                Edit
            </Button>
            <Modal
                onClose={() => setIsOpen(false)}
                isOpen={isOpen}
                title={title}
                showCloseButtonInHeader={true}
                tabHeaders={<TabHeaders />}
                size="xl"
                footerActions={footerActions}
            >
                <Form
                    onSubmit={handleSubmit}
                    defaultValues={defaultValues}
                    id={formId}
                    validationSchema={coreDetailsFormSchema}
                >
                    <TabContent />
                </Form>
            </Modal>
        </>
    );
};

export { CareOfferedModal };
