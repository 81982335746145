const booleanToYesNoOrNull = (value: boolean) => {
    if (typeof value !== 'boolean') {
        return null;
    }
    return value ? 'Yes' : 'No';
};

const mapValuesToFormFields = (values: any) => {
    return {
        LongTermCare: booleanToYesNoOrNull(values.LongTermCare),
        ShortTermCare: booleanToYesNoOrNull(values.ShortTermCare),
        RespiteCare: booleanToYesNoOrNull(values.RespiteCare),
        AdmitPediatricResidents: booleanToYesNoOrNull(values.AdmitPediatricResidents),
        LongTermSharedCostPerDay: values.SharedLongTermCost,
        LongTermPrivateCostPerDay: values.PrivateLongTermCost,
        LongTermSemiPrivateCostPerDay: values.SemiPrivateLongTermCost,
        AcceptMedicareAdvantage: booleanToYesNoOrNull(values.AcceptMedicareAdvantagePlans),
        AcceptMedicaidPending: booleanToYesNoOrNull(values.AcceptMedicaidPendingPatients),
        ManagedCareContractsInNetwork: booleanToYesNoOrNull(values.ManagedCareContractsInNetwork),
        AvgLongTermResidentsPc: values.AvgLongTermResidentsPc,
        AvgShortTermResidentsPc: values.AvgShortTermResidentsPc,
        AvgShortStayDuration: values.AvgShortStayDuration,
        AvgLongTermStayDuration: values.AvgLongTermStayDuration,
        PediatricAvgLongTermStayDuration: values.PediatricAvgLongTermStayDuration,
        PediatricAvgLongTermResidentsPc: values.PediatricAvgLongTermResidentsPc,
        PediatricAvgShortStayDuration: values.PediatricAvgShortStayDuration,
        PediatricAvgShortTermResidentsPc: values.PediatricAvgShortTermResidentsPc,
        PediatricMaxAgeRequirement: booleanToYesNoOrNull(values.PediatricMaxAgeRequirement),
        PediatricMaxAge: values.PediatricMaxAge,
        AffiliatedHospitals: values.AffiliatedHospitals,
        RoomTypeOther: values.RoomTypeOther,
        LongTermRatePackagesAvailable: booleanToYesNoOrNull(values.LongTermRatePackagesAvailable),
        LongTermRatesAllInclusive: booleanToYesNoOrNull(values.LongTermRatesAllInclusive),
        ShortTermRatePackagesAvailable: booleanToYesNoOrNull(values.ShortTermRatePackagesAvailable),
        ShortTermRatesAllInclusive: booleanToYesNoOrNull(values.ShortTermRatesAllInclusive),
        ShortTermPrivateCostPerDay: values.ShortTermPrivateCostPerDay,
        ShortTermSemiPrivateCostPerDay: values.ShortTermSemiPrivateCostPerDay,
        ShortTermSharedCostPerDay: values.ShortTermSharedCostPerDay,
        RespiteSharedCostPerDay: values.RespiteSharedCostPerDay,
        RespitePrivateCostPerDay: values.RespitePrivateCostPerDay,
        RespiteSemiPrivateCostPerDay: values.RespiteSemiPrivateCostPerDay,
        RespiteMinStayRequirement: booleanToYesNoOrNull(values.RespiteMinStayRequirement),
        RespiteMinStay: values.RespiteMinStay,
        RespiteRatesAllInclusive: booleanToYesNoOrNull(values.RespiteRatesAllInclusive),
        RespiteRatePackagesAvailable: booleanToYesNoOrNull(values.RespiteRatePackagesAvailable),
        InsuranceTypes: values.InsuranceDetails.map(({IsActive, ...rest}: any) => ({
            ...rest,
            IsActive: booleanToYesNoOrNull(IsActive)
        })),
        FacilityHasMemoryCareUnit: booleanToYesNoOrNull(values.FacilityHasMemoryCareUnit),
        FacilityHasBehaviouralHealthUnit: booleanToYesNoOrNull(values.FacilityHasBehaviouralHealthUnit),
        OtherFacilityTypes: values.OtherFacilityTypes,
        MinAgeRequirement: booleanToYesNoOrNull(values.MinAgeRequirement),
        MinAge: values.MinAge,
        BedsMedicareCount: values.BedsMedicareCount,
        BedsMedicaidCount: values.BedsMedicaidCount,
        BedsAllDualLicenced: booleanToYesNoOrNull(values.BedsAllDualLicenced),
        PrivateInsurancePc: values.PrivateInsurancePc,
        RoomTypeShared: booleanToYesNoOrNull(values.RoomTypeShared),
        RoomTypePrivateRoom: booleanToYesNoOrNull(values.RoomTypePrivateRoom),
        RoomTypeSemiPrivate: booleanToYesNoOrNull(values.RoomTypeSemiPrivate),
        RoomTypePrivateSuite: booleanToYesNoOrNull(values.RoomTypePrivateSuite),
    };
};

export {
    mapValuesToFormFields
};