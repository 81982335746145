import React, {
    ComponentType,
    ReactNode,
    useCallback,
    useMemo,
    useRef,
    useState
} from 'react';
import cx from 'classnames';
import { getNearestScrollingAncestor } from '../utils/dom';

interface ITabProps {
    children: React.ReactNode;
    isActive: boolean;
    onClick: () => void;
}

interface ITab<T = string> {
    tabInfo?: ReactNode;
    tabHeader: ReactNode;
    tabKey: T;
    tabContent: ComponentType;
}

interface ITabOptions {
    isScrollTab?: boolean;
}

const Tab = (props: ITabProps) => {
    const { children, isActive, onClick } = props;

    const listItemClassName = cx('line-height-1 cursor-pointer text-center', {
        'border-bottom border-3 border-primary text-primary': isActive
    });

    return (
        <li className={listItemClassName}>
            <button
                style={{ all: 'unset', lineHeight: '1.3' }}
                onClick={onClick}
            >
                {children}
            </button>
        </li>
    );
};

const useCreateTabs = (tabConfig: ITab[], options: ITabOptions = {}) => {
    const { isScrollTab = false } = options;
    const firstTabKey = tabConfig[0].tabKey;
    const scrollParentRef = useRef(null);
    const sectionRefs = useRef([]);
    const [activeTab, setActiveTab] = useState(firstTabKey);

    const tabTitles = useMemo(
        () =>
            tabConfig.map((tab: ITab, index: number) => (
                <Tab
                    isActive={activeTab === tab.tabKey}
                    key={index}
                    onClick={() => {
                        if (isScrollTab) {
                            const tabElement = document.getElementById(
                                `tab-${tab.tabKey}`
                            );
                            tabElement.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start'
                            });
                        } else {
                            setActiveTab(tab.tabKey);
                        }
                    }}
                >
                    {tab.tabHeader}
                </Tab>
            )),
        [tabConfig, activeTab]
    );

    // only used when in scroll tab mode, this tracks which section is visible to update the tab header styling
    const setRef = (node: HTMLElement) => {
        let observer: IntersectionObserver;
        if (node) {
            scrollParentRef.current = getNearestScrollingAncestor(node);
            observer = new IntersectionObserver(
                (entries) => {
                    const visibleEntries = entries.filter(
                        (entry) => entry.isIntersecting
                    );

                    // Find the first visible entry (the highest one)
                    if (visibleEntries.length > 0) {
                        setActiveTab(
                            (visibleEntries[0].target as HTMLElement).dataset
                                .tabkey
                        );
                    }
                },
                {
                    root: scrollParentRef.current,
                    threshold: 0,
                    rootMargin: '0px 0px -100% 0px'
                }
            );

            sectionRefs.current.forEach((item) => observer.observe(item));
        } else {
            setActiveTab(firstTabKey);
            if (observer) {
                sectionRefs.current.forEach((item) => observer.unobserve(item));
            }
        }
    };

    const activeTabInfo = tabConfig.find(
        (tab: ITab) => tab.tabKey === activeTab
    )?.tabInfo;

    const renderTabInfo = (tabInfo: ReactNode) => {
        if (!tabInfo) return null;
        return (
            <div className={cx('fs-6 p-4 bg-light', { 'mt-3': isScrollTab })}>
                {tabInfo}
            </div>
        );
    };

    const tabHeaders = () => (
        <>
            <div className="border-bottom border-2 fs-6 shadow-sm pt-2">
                <ul className="list-unstyled d-flex gap-4 mb-0 px-3 overflow-x-auto">
                    {tabTitles}
                </ul>
            </div>
            {!isScrollTab && renderTabInfo(activeTabInfo)}
        </>
    );

    const tabContent = tabConfig
        .filter((tab: ITab) => {
            if (isScrollTab) return true;
            return tab.tabKey === activeTab;
        })
        .map(({ tabContent: Tab, tabKey, tabInfo }, index: number) => (
            <div
                key={tabKey}
                id={`tab-${tabKey}`}
                {...(isScrollTab
                    ? { ref: (el) => (sectionRefs.current[index] = el) }
                    : {})}
                data-tabkey={tabKey}
            >
                {isScrollTab && renderTabInfo(tabInfo)}
                <Tab />
            </div>
        ));

    const tabContentWrapper = useCallback(
        () => (
            <div className="py-3 px-3" {...(isScrollTab ? { ref: setRef } : {})}>
                {tabContent}
            </div>
        ),
        [...(!isScrollTab ? tabContent : [])]
    );

    return { TabHeaders: tabHeaders, TabContent: tabContentWrapper };
};

export { useCreateTabs, type ITab };
