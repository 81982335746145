import React from 'react';

interface Props {
    title: string;
    subtitle?: string;
}

const SectionHeading = ({title, subtitle}: Props) => {
    return (
        <div className='bg-light py-3 ps-2'>
            <h4 className='m-0'>{title}</h4>
            {subtitle && <p className="fs-6 m-0">{subtitle}</p>}
        </div>
    );
};

export { SectionHeading };