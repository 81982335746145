import React, { FC, InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import { ConnectForm } from '../form/form';
import cx from 'classnames';
import { UseFormReturn } from 'react-hook-form';

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    name: string;
    rows?: number;
    labelStyle?: 'normal' | 'bold';
    inline?: boolean;
    description?: ReactNode;
}

type FieldRequired = { isRequired: (fieldName: string) => boolean };

const FormTextArea: FC<Props> = (props) => {
    const {
        name,
        id,
        label,
        className,
        description,
        maxLength,
        rows = 3,
        inline = true,
        labelStyle = 'normal',
        ...rest
    } = props;

    return (
        <ConnectForm>
            {({
                register,
                formState,
                watch,
                isRequired
            }: UseFormReturn & FieldRequired) => {
                const fieldError: { message?: string } =
                    formState?.errors?.[name];
                const errorMessage: ReactElement = (
                    <small className="text-danger position-absolute">
                        {fieldError?.message}
                    </small>
                );

                const textAreaClassName: string = cx(
                    'form-control',
                    {
                        'border border-danger': fieldError
                    },
                    className
                );

                const textAreaId: string = id || name;

                const inputWrapperClassName = cx('d-flex flex-column',{
                    'mt-3': label,
                    'flex-sm-row justify-items-center': inline,
                });

                const characterCountDisplay: ReactElement = (
                    <div
                        className={cx(
                            'position-absolute bottom-0 end-0 pb-1 text-muted text-sm',
                            { 'pr-4': inline, 'pr-3': !inline }
                        )}
                    >
                        {watch(name)?.length || 0}/{maxLength}
                    </div>
                );

                const labelClassName = cx('fs-md-5', {
                    'd-flex justify-content-center me-sm-5 flex-column': inline,
                    'fw-label': labelStyle === 'bold',
                    'mb-1': !inline
                });
                
                return (
                    <div
                        className={inputWrapperClassName}
                        style={fieldError ? { marginBottom: '30px' } : {}}
                    >
                        {label && (
                            <div
                                className={cx(
                                    // 'd-flex flex-column',
                                    labelClassName,
                                )}
                                style={{
                                    width: inline ? '150px' : '100%',
                                    // minWidth: inline ? '350px' : '100%',
                                    maxWidth: '100%'
                                }}
                            >
                                <label htmlFor={textAreaId}>
                                    {label}
                                    {isRequired(name) && '*'}
                                </label>
                                {description && (
                                    <span className="fw-normal fst-italic fs-6 lh-sm text-muted">
                                        {description}
                                    </span>
                                )}
                            </div>
                        )}
                        <div
                            className={cx(
                                'position-relative',
                                'flex-fill'
                            )}
                        >
                            <textarea
                                cols={30}
                                rows={rows}
                                id={textAreaId}
                                className={textAreaClassName}
                                {...register?.(name)}
                                {...rest}
                            ></textarea>
                            {maxLength && characterCountDisplay}
                            {fieldError && errorMessage}
                        </div>
                    </div>
                );
            }}
        </ConnectForm>
    );
};

export { FormTextArea };
