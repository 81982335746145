import React, { useCallback, useContext, useMemo, useState } from 'react';
import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import DataTable, { DataTableSortOrder } from '../../../components/common/data_table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import ProfileCompletenessHeader from '../../../components/profile_completeness/profile_completeness_header';
import { 
    ProfileScoreType,
    IPropertyCoreDetailNHID,
    INhPortalScoreItem,
    INhPropertyScores,
    INhPropertyScoresItem,
    IPropertyCoreDetailInsuranceType
} from '../../../interfaces/property';

import { getPropertyCoreDetails } from '../../../api/property';
import { useSelector } from 'react-redux';
import { ISessionState, ISessionProperty } from '../../../interfaces/session';
import PropertyCoreDetailsForm from './property_core_details_form';
import { boolSort, textSort } from '../../../utils/common';
import AppContext from '../../../context/app_context';
import { useCommunitySelectHistory } from '../../../hooks/use_community_select_history';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { CareOfferedModal } from './care_offered_modal';
import { useQuery } from '@tanstack/react-query';
import { RootState } from '../../../store/reducers';
import { longFormatDate } from '../../../utils/date';
import PageTitle from '../../../components/common/page_title';


interface IProps {
    nhIDs: number[];
    orgNHID: number;
    pageTitle: string;
}

interface ITableDataRow {
    NHID: number;
    HasCoreDetails?: boolean;
    Name: string;
    Address: string;
    ProfileComplete: boolean;
    LongTermCare: boolean;
    ShortTermCare: boolean;
    RespiteCare: boolean;
    AdmitPediatricResidents: boolean;
    RoomTypesOffered: string[];
    SharedLongTermCost: string;
    SharedShortTermCost: string;
    SharedRespiteCost: string;
    PrivateLongTermCost: string;
    PrivateShortTermCost: string;
    PrivateRespiteCost: string;
    SemiPrivateLongTermCost: string;
    SemiPrivateShortTermCost: string;
    SemiPrivateRespiteCost: string;
    LastUpdated: string;
    MedicaidBedCount: string;
    MedicareBedCount: string;
    AcceptMedicareAdvantagePlans: boolean;
    AcceptMedicaidPendingPatients: boolean;
    InsuranceDetails: IPropertyCoreDetailInsuranceType[];
    FacilityHasMemoryCareUnit: boolean;
    FacilityHasBehaviouralHealthUnit: boolean;
    OtherFacilityTypes: string;
    MinAgeRequirement: boolean;
    MinAge: number;
    WebsiteURL: string; 
    StateSlug: string;
    CitySlug: string;
    Slug: string;
}

type ActiveInsuranceCount = number;
type TotalInsuranceCount = number;

const getMinAgeRequirement = (hasMinAgeRequirement: boolean | null, minAge?: number): string => {
    if (typeof hasMinAgeRequirement !== 'boolean' || !minAge) {
        return 'Unknown';
    }
    return hasMinAgeRequirement ? `Yes (${minAge})` : 'No';
};


const yesNoOrUnknown = (value: boolean | null): string => {
    // eslint-disable-next-line eqeqeq
    if (value == null) {
        return 'Unknown';
    }
    return value  ? 'Yes' :  'No';
};

const valueOrPlaceholder = (value: string | number | null, placeholder: string = 'Unknown'): string => {
        // eslint-disable-next-line eqeqeq
        if (value == null) {
            return placeholder;
        }

        return value.toString();
};

const getRoomTypesOffered = (item: IPropertyCoreDetailNHID): string[] => {
    const roomTypeArray = [];
    if (item.CoreDetails) {
      
        const types = [
            ['RoomTypePrivateRoom', 'Private'],
            ['RoomTypePrivateSuite', 'Private Suite'],
            ['RoomTypeSemiPrivate', 'Semi Private'],
            ['RoomTypeShared', 'Shared']
        ];

        for (const type of types) {
            if ((item.CoreDetails as any)[type[0]] === true) {
                roomTypeArray.push(type[1]);
           }
        }

        if (item.CoreDetails?.RoomTypeOther) {
            roomTypeArray.push(item.CoreDetails.RoomTypeOther);
        }
    }

    if (!roomTypeArray.length) {
        roomTypeArray.push('None Selected');
    }

    return roomTypeArray;
};

const formatCostOrUnknown = (cost: number | string): string => {
    if (!cost) {
        return 'Unknown';
    }

    return `$${cost}`;
};

const getInsuranceDetails = (item: IPropertyCoreDetailInsuranceType[]): [ActiveInsuranceCount, TotalInsuranceCount] => {
    if (!item) {
        return [0,0];
    }

    return [
        item.reduce((acc: number, val: IPropertyCoreDetailInsuranceType) => 
                acc + (val.IsActive ? 1 : 0), 0),
        item.length
    ];
};


const PropertyCoreDetails = ({ nhIDs, orgNHID, pageTitle }: IProps) => {

    const [ selNHID, setSelNHID ] = useState<number>(null);
    const [sortField, setSortField] = useState<string>('Name');
    const [sortOrder, setSortOrder] = useState<DataTableSortOrder>(DataTableSortOrder.Ascending);
    const [ profileCompletenessFilterValue, setProfileCompletenessFilterValue ] = useState<boolean | null>(null);

    const sessionState: ISessionState = useSelector(
        ({SessionState}: RootState) => SessionState
    );

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: RootState) => ProfileCompletenessState
    );

    const singlePropertySession = nhIDs?.length === 1;
    const singleNHID = singlePropertySession ? nhIDs[0] : selNHID;

    const appContext: any = useContext(AppContext);

    const { data: coreDetailData = [] } = useQuery({
        queryKey: ['property', 'core', 'details'],
        queryFn: getPropertyCoreDetails
    });

    
    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });


    const getSessionData = useCallback((NHID: number) => {

        const property = (sessionState?.Properties || []).find((item: ISessionProperty) => (
            item.NHID === NHID
        ));

        const Addr =  property ? [
            property.Address1,
            property.City,
            property.PostCode,
            property.RegionCode
        ].join(', ') : '';

        const WebsiteURL = property?.WebsiteURL;
        const StateSlug = property?.StateSlug;
        const CitySlug = property?.CitySlug;
        const Slug = property?.Slug;

        return { Addr, WebsiteURL, StateSlug, CitySlug, Slug };
    }, [sessionState]);

    const getName = (NHID: number) => {

        const property = (sessionState?.Properties || []).find((item: ISessionProperty) => (
            item.NHID === NHID
        ));

        return property?.Name || '';
    };

    const getProfileComplete = useCallback((NHID: number) => {
    
        const propertyScore = (profileCompletenessState?.PropertyScores || []).find((item: INhPropertyScores) => (
            item.NHID === NHID
        ));

        const coreScore = (propertyScore?.Scores || []).find((item: INhPropertyScoresItem) => (
            item.KeyName === ProfileScoreType.FacilityBaseData
        ));

        return coreScore ? coreScore.Score === coreScore.AvailableScore : false;
    }, [profileCompletenessState]);

    const sortFn = useMemo(() => {
        switch(sortField) {
            case'Name':
                return textSort;
            case 'ProfileComplete':
                return boolSort;
            case 'LastUpdated':
                return textSort;
            default:
              console.log(`No sort function declared for column ${sortField}`);
        }
    }, [sortField]);

    const rawTableData = useMemo(() => coreDetailData.map((item: IPropertyCoreDetailNHID): ITableDataRow => {
        const name = getName(item.NHID);
        const { Addr, WebsiteURL, StateSlug, CitySlug, Slug } = getSessionData(item.NHID);
        const profileComplete = getProfileComplete(item.NHID);
        return {
            NHID: item.NHID,
            HasCoreDetails: !!item.CoreDetails,
            LastUpdated: item.CoreDetails?.LastUpdated,
            Name: name,
            Address: Addr,
            ProfileComplete: profileComplete,
            LongTermCare: item.CoreDetails?.LongTermCare,
            ShortTermCare: item.CoreDetails?.ShortTermCare,
            RespiteCare: item?.CoreDetails?.RespiteCare,
            AdmitPediatricResidents: item.CoreDetails?.AdmitPediatricResidents,
            RoomTypesOffered: getRoomTypesOffered(item),
            SharedLongTermCost: item.CoreDetails?.LongTermSharedCostPerDay?.toString(),
            SharedShortTermCost: item?.CoreDetails?.ShortTermSharedCostPerDay?.toString(),
            SharedRespiteCost: item?.CoreDetails?.RespiteSharedCostPerDay?.toString(),
            PrivateLongTermCost: item?.CoreDetails?.LongTermPrivateCostPerDay?.toString(),
            PrivateShortTermCost: item?.CoreDetails?.ShortTermPrivateCostPerDay?.toString(),
            PrivateRespiteCost: item?.CoreDetails?.RespitePrivateCostPerDay?.toString(),
            SemiPrivateLongTermCost: item?.CoreDetails?.LongTermSemiPrivateCostPerDay?.toString()    ,
            SemiPrivateShortTermCost: item?.CoreDetails?.ShortTermSemiPrivateCostPerDay?.toString(),
            SemiPrivateRespiteCost: item?.CoreDetails?.RespiteSemiPrivateCostPerDay?.toString(),
            MedicaidBedCount: item.CoreDetails?.BedsMedicaidCount?.toString(),
            MedicareBedCount: item.CoreDetails?.BedsMedicareCount?.toString(),
            AcceptMedicareAdvantagePlans: item.CoreDetails?.AcceptMedicareAdvantage,
            AcceptMedicaidPendingPatients: item.CoreDetails?.AcceptMedicaidPending,
            ManagedCareContractsInNetwork: item.CoreDetails?.ManagedCareContractsInNetwork,
            InsuranceDetails: item?.InsuranceTypes,
            FacilityHasMemoryCareUnit: item.CoreDetails?.FacilityHasMemoryCareUnit,
            FacilityHasBehaviouralHealthUnit: item.CoreDetails?.FacilityHasBehaviouralHealthUnit,
            OtherFacilityTypes: item.CoreDetails?.OtherFacilityTypes,
            MinAgeRequirement: item.CoreDetails?.MinAgeRequirement,
            MinAge: item.CoreDetails?.MinAge,
            WebsiteURL,
            StateSlug,
            CitySlug,
            Slug,
            ...item.CoreDetails
        };
    }).sort(sortFn(sortField, sortOrder)), [coreDetailData, sortField, sortOrder, getSessionData, getProfileComplete]);
   

    const filteredTableData = rawTableData.filter((item: ITableDataRow) => {
        
        const nhIDValid = !selNHID || item.NHID === selNHID;
        
        const profileCompletenessFilterValid = profileCompletenessFilterValue === null || 
        profileCompletenessFilterValue === item.ProfileComplete;
        
        return nhIDValid && profileCompletenessFilterValid;
    });


    const formatProfileComplete = (row: ITableDataRow) => {

        return (
            <div className="d-block fw-bold text-nowrap text-center w-100">
                <FontAwesomeIcon 
                    icon={row.ProfileComplete ? faCheck : faXmark} 
                    className={`fe-2 ${row.ProfileComplete ? 'text-success' : 'text-danger'} fs-2`}
                /> 
            </div>
        );
    };

    const formatRoomTypesOffered = (row: ITableDataRow) => {
        return (
            <div className="py-4">
                {
                    row.RoomTypesOffered.map((item: string) => (
                        <div className="py-0 m-1">{item}</div>
                    ))
                }
            </div>
        );
    };

    const formatCostPerDay = (row: ITableDataRow) => {

        if (
            !row.RoomTypesOffered.includes('Shared') &&
            !row.RoomTypesOffered.includes('Private') &&
            !row.RoomTypesOffered.includes('Semi Private') 
        ) {
            return 'None Selected';
        }

        return (
            <div className="py-4">

            {
                row.RoomTypesOffered.includes('Shared') ?
                <div className="py-1">
                    <span className="fw-semibold">Shared</span><br></br>
                    <span className="py-0 mt-1"><em>Long Term:</em> {formatCostOrUnknown(row.SharedLongTermCost)}</span><br></br>
                    <span className="py-0 mt-1"><em>Short Term:</em> {formatCostOrUnknown(row.SharedShortTermCost)}</span><br></br>
                    <span className="py-0 mt-1"><em>Respite:</em> {formatCostOrUnknown(row.SharedRespiteCost)}</span>
                </div> : null
            }
            {
                row.RoomTypesOffered.includes('Private') ?
                <div className="mt-4 py-1">
                    <span className="fw-semibold">Private</span><br></br>
                    <span className="py-0 mt-1"><em>Long Term:</em> {formatCostOrUnknown(row.PrivateLongTermCost)}</span><br></br>
                    <span className="py-0 mt-1"><em>Short Term:</em> {formatCostOrUnknown(row.PrivateShortTermCost)}</span><br></br>
                    <span className="py-0 mt-1"><em>Respite:</em> {formatCostOrUnknown(row.PrivateRespiteCost)}</span>
                </div>: null
            }
            {
                row.RoomTypesOffered.includes('Semi Private') ?
                <div className="mt-4 py-1">
                    <span className="fw-semibold">Semi-private</span><br></br>
                    <span className="py-0 mt-1"><em>Long Term:</em> {formatCostOrUnknown(row.SemiPrivateLongTermCost)}</span><br></br>
                    <span className="py-0 mt-1"><em>Short Term:</em> {formatCostOrUnknown(row.SemiPrivateShortTermCost)}</span><br></br>
                    <span className="py-0 mt-1"><em>Respite:</em> {formatCostOrUnknown(row.SemiPrivateRespiteCost)}</span>
                </div>: null
            }
        </div>
        );
    };

    const formatPaymentType = (row: ITableDataRow) => {
        return (
            <div>
                <div className="py-1">
                    <span className="fw-semibold">Medicaid Bed Count</span><br></br>
                    <span className="py-0 mt-1">{valueOrPlaceholder(row.MedicaidBedCount)}</span>
                </div>
                <div className="mt-3 py-1">
                    <span className="fw-semibold">Medicare Bed Count</span><br></br>
                    <span className="py-0 mt-1">{valueOrPlaceholder(row.MedicareBedCount)}</span>
                </div>
                <div className="mt-3 py-1">
                    <span className="fw-semibold">Accept Medicare Advantage plans</span><br></br>
                    <span className="py-0 mt-1">{yesNoOrUnknown(row.AcceptMedicareAdvantagePlans)}</span>
                </div>
                <div className="mt-3 py-1">
                    <span className="fw-semibold">Accept Medicaid pending patients</span><br></br>
                    <span className="py-0 mt-1">{yesNoOrUnknown(row.AcceptMedicaidPendingPatients)}</span>
                </div>
            </div>
        );
    };
    
    const formatInsuranceTypes = (row: ITableDataRow) => {
        const [activeInsuranceCount, totalInsuranceCount] = getInsuranceDetails(row.InsuranceDetails);

        return (
            <div>
                <div className="d-block fw-bold text-nowrap text-center w-100">
                    {activeInsuranceCount} / {totalInsuranceCount}
                </div><small className="text-nowrap">completed</small></div>
        );
    };

    const renderCareOffered  = (row: ITableDataRow) => <div className="py-2">
        <p><strong>Long Term Care</strong>: {yesNoOrUnknown(row?.LongTermCare)  }</p>
        <p><strong>Short Term Care</strong>: {yesNoOrUnknown(row?.ShortTermCare)}</p>
        <p><strong>Respite Care</strong>: {yesNoOrUnknown(row?.RespiteCare)}</p>
        <p><strong>Facility has a <br/>Memory Care Unit</strong>: {yesNoOrUnknown(row?.FacilityHasMemoryCareUnit)}</p>
        <p><strong>Facility has a <br/>Behavioural Health Unit</strong>: {yesNoOrUnknown(row?.FacilityHasBehaviouralHealthUnit)}</p>
        <p><strong>Speciality Niche Services</strong>: {valueOrPlaceholder(row?.OtherFacilityTypes, 'None Listed')}</p>
        <p><strong>Minimum Age</strong>: {getMinAgeRequirement(row?.MinAgeRequirement, row?.MinAge)}</p>
        <p><strong>Pediatric Residents</strong>: {yesNoOrUnknown(row?.AdmitPediatricResidents)}</p>
    </div>;

    const formatName = (row: ITableDataRow) => {

        const { WebsiteURL, StateSlug, CitySlug, Slug } = row;

        return (
            <div className="py-2">
                <a 
                    href="#" 
                    className='fw-bolder d-block'
                    onClick={(e) => handleLinkClick(row.NHID, e)}
                >
                    { row.Name }
                </a>
                <small>{ row.Address }</small>&nbsp;
                <a
                    className="small text-link text-link-blue text-nowrap"
                    href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                    target="_blank"
                >
                    view on website
                </a>    
            </div>  
        );
    };

    const columns = [
        {
            id: 'NHID',
            name: 'Facility Name',
            sortField: 'Name',
            sortable: true,
            minWidth: appContext.isMobile ? '146px' : '190px',
            cell: (row: ITableDataRow) => formatName(row)
        },
        {
            name: <>Profile Complete for Care Offered / Cost / Funding</>,
            sortable: true,
            sortField: 'ProfileComplete',
            width: '160px',
            cell: (row: ITableDataRow) => formatProfileComplete(row)
        },
        {
            name: 'Care Offered',
            width: '300px',
            cell: renderCareOffered
        },
        {
            name: 'Room Types Offered',
            width: '140px',
            cell: (row: ITableDataRow) => formatRoomTypesOffered(row)
        },
        {
            name: 'Cost (starting at per day)',
            width: '180px',
            cell: (row: ITableDataRow) => formatCostPerDay(row)
        },
        {
            name: 'Bed Allocation and Payment Types',
            minWidth: '180px',
            cell: (row: ITableDataRow) => formatPaymentType(row)
        },
        {
            name: 'Insurance Providers Accepted',
            width: '105px',
            cell: (row: ITableDataRow) => formatInsuranceTypes(row)
        },
        {
            name: 'Last Updated',
            sortable: true,
            sortField: 'LastUpdated',
            cell: (row: ITableDataRow) => <div className='w-100'>
                <CareOfferedModal row={row} hasCoreDetails={row.HasCoreDetails}/>
                <div className='fs-6 mt-4'>{row.LastUpdated ? longFormatDate(row.LastUpdated): 'No updates made'}</div>
            </div>,
            width: '120px',
            id: 'Actions'
        }
    ];

    const handleLinkClick = (
        nhID: number, 
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        e.preventDefault();
        handleSelect(nhID);
        window.scrollTo(0, 0);
    };

    const handleTableSortChange = (
        column: TableColumn<ITableDataRow>,
        sortOrder: SortOrder
    ) => {
        setSortField(column.sortField);
        setSortOrder(
            sortOrder === 'asc'
                ? DataTableSortOrder.Ascending
                : DataTableSortOrder.Descending
        );
    };

    const selectedFacility = useMemo(() => {
        const currentSelectedRow = rawTableData.find((row: ITableDataRow) => selNHID === row.NHID);
        return singlePropertySession ? rawTableData[0] : currentSelectedRow;
    }, [rawTableData, selNHID]);

    return (
        <>
            <div className='d-flex mb-10 align-items-sm-center justify-content-between flex-column flex-sm-row flex-wrap'>
            <PageTitle title={pageTitle}/>
            { 
                selectedFacility && <div>
                    {   selectedFacility.LastUpdated 
                            ? `Last Updated: ${longFormatDate(selectedFacility.LastUpdated)}`
                            : 'No updates made'
                    }
                </div>
            }
            </div>
            <PageCommunitySelectWrapper
                label={'Care Offered / Cost / Funding'}
                selNHID={selNHID}
                handleSelect={handleSelect}
            />
            
            <div>
                <div className="widget" data-widget-height="auto">
                    <div className="widget-body">                          
                        <ProfileCompletenessHeader
                            selNHID={selNHID}
                            nhIDs={nhIDs}
                            orgNHID={orgNHID}
                            profileScoreType={ProfileScoreType.FacilityBaseData}
                            handleProfileCompletenessFilter={setProfileCompletenessFilterValue}
                            profileCompletenessFilterValue={profileCompletenessFilterValue}
                            title="for Care Offered / Cost / Funding"
                        />
                    </div> 
                </div> 
                <div className="widget">
                    <div className="widget-body">
                    { 
                        !singlePropertySession && !selNHID
                            ?  
                        <div className={'coredetails_dt sticky_dt table_dt expander_dt overflow_dt'}>
                            <DataTable
                                columns={columns}
                                onSort={handleTableSortChange}
                                data={filteredTableData}

                            />
                        </div>  
                            : 
                            <PropertyCoreDetailsForm
                                nhID={singleNHID} 
                            />
                    }
                    </div>
                </div>
            </div>
        </>
    );
};


export default PropertyCoreDetails;