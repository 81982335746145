import Select, { StylesConfig, ClassNamesConfig } from 'react-select';
import { ConnectForm } from '../form/form';
import React, { FC, InputHTMLAttributes, ReactElement } from 'react';
import cx from 'classnames';

type Option = { label: string; value: any };

interface Props extends InputHTMLAttributes<HTMLSelectElement> {
    label?: string;
    name: string;
    options: Option[];
    isMulti?: boolean;
    inline?: boolean;
    labelStyle?: 'normal' | 'bold';
    inlineLabelWidth?: 'small' | 'large';
}

const FormSelect: FC<Props> = (props) => {
    const {
        name,
        id,
        label,
        options,
        inline = true,
        labelStyle = 'normal',
        inlineLabelWidth = 'small',
        ...rest
    } = props;

    const inlineLabelWidths = {
        small: 150,
        large: 250
    };

    return (
        <ConnectForm>
            {(methods: any) => {
                const { register, formState, setValue, watch, isRequired } =
                    methods;
                const fieldError = formState?.errors?.[name];
                const inputId: string = id || name;
                const currentSelectedValue = watch(name);

                const errorMessage: ReactElement = (
                    <small className="text-danger position-absolute">
                        {fieldError?.message}
                    </small>
                );

                const selectInputStyles: StylesConfig = {
                    control: (baseStyles, { isFocused }) => ({
                        ...baseStyles,
                        boxShadow: isFocused
                            ? '0 0 0 0.25rem rgba(13, 110, 253, 0.25)'
                            : 'none',
                        borderRadius: 'var(--bs-border-radius)'
                    })
                };

                const selectInputClassNames: ClassNamesConfig = {
                    control: ({ isFocused }) =>
                        cx('border fs-5', {
                            'border-info': isFocused && !fieldError,
                            'border-danger': !!fieldError
                        }),
                    option: () => 'fs-5'
                };

                const filterValue: Option[] = options.filter(
                    ({ value }: Option) =>
                        value === Number(currentSelectedValue)
                );

                const inputWrapperClassName = cx('d-flex flex-column', {
                    'mt-3': label,
                    'flex-sm-row justify-items-center': inline
                });

                const labelClassName = cx('fs-md-5', {
                    'd-flex justify-content-center me-sm-5 flex-column': inline,
                    'fw-label': labelStyle === 'bold',
                    'mb-1': !inline
                });
                return (
                    <div
                        className={inputWrapperClassName}
                        style={fieldError ? { marginBottom: '30px' } : {}}
                    >
                        {label && (
                            <label
                                htmlFor={inputId}
                                className={labelClassName}
                                style={{
                                    width: inline ? `${inlineLabelWidths[inlineLabelWidth]}px` : '100%',
                                    maxWidth: '100%'
                                }}
                            >
                                {label}
                                {isRequired(name) && '*'}
                            </label>
                        )}
                        <div
                            className={cx(
                                'position-relative flex-fill',
                            )}
                        >
                            <Select
                                {...register(name)}
                                {...rest}
                                styles={selectInputStyles}
                                classNames={selectInputClassNames}
                                id={inputId}
                                options={options}
                                value={filterValue}
                                onChange={(val: Option) =>
                                    setValue(name, val?.value)
                                }
                            />
                            {fieldError && errorMessage}
                        </div>
                    </div>
                );
            }}
        </ConnectForm>
    );
};

export { FormSelect };
